import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ProductList from './components/Products/ProductList';
import PrinterList from './components/Printers/PrinterList';
import MaterialList from './components/Materials/MaterialList';
import ManageTickets from './components/Admin/ManageTickets';
import ToDoList from './components/Admin/ToDoList';
import Login from './components/Users/Login';
import Header from './components/Common/Header';

const routing = (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/products" element={<ProductList />}></Route>
      <Route path="/printers" element={<PrinterList />}></Route>
      <Route path="/materials" element={<MaterialList />}></Route>
      <Route path="/tickets" element={<ManageTickets />}></Route>
      <Route path="/todos" element={<ToDoList />}></Route>
    </Routes>
  </BrowserRouter>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(routing);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from 'react';
import './PrinterList.css';
import Header from '../Common/Header';
import Navigation from '../Common/Navigation';
import { Fab, Icon, Button, SwipeableDrawer, TextField, FormControl, Snackbar, Alert, Modal, Box, InputLabel, Select, MenuItem } from '@mui/material';
import settings from '../../settings.json';

export default function ProductList() {

    const [showAddPrinter, setShowAddPrinter] = React.useState(false);
    const [showEditPrinter, setShowEditPrinter] = React.useState(false);
    const [selectedPrinter, setSelectedPrinter] = React.useState(false);
    const [openNotification, setOpenNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState("");
    const [allPrinters, setAllPrinters] = React.useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

    React.useEffect(() => {
        getAllPrinters();
    }, []);

    function getAllPrinters() {
        fetch(settings.API_URL + 'api/printers/getall')
            .then(data => data.json())
            .then(data => {
                setAllPrinters(data);
            });
    }

    function add() {
        setShowAddPrinter(true);

    }


    function edit(item) {
        setShowEditPrinter(true);
        setSelectedPrinter(item);
    }


    function confirmDelete(item) {
        setConfirmDeleteOpen(true);
        setSelectedPrinter(item);
    }

    function closeConfirmDelete() {
        setConfirmDeleteOpen(false);
    }

    function deteteItem() {
        setConfirmDeleteOpen(false);

        const url = settings.API_URL + "api/printers/delete";

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(selectedPrinter),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Printer removed!");
            getAllPrinters();
        });
    }

    function closeNotification() {
        setOpenNotification(false);
    }

    function closeAdd() {
        setShowAddPrinter(false);
    }
    function closeEdit() {
        setShowEditPrinter(false);
    }

    function openAdd() { }
    function openEdit() { }

    function submitNewPrinter(e) {
        e.preventDefault();

        const url = settings.API_URL + "api/printers/add";

        const newPrinter =
        {
            name: e.target.name.value,
            status: e.target.status.value
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newPrinter),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("save done");
            setOpenNotification(true);
            setNotificationMsg("New printer added!");
            getAllPrinters();
            closeAdd();
        });
    }

    function submitUpdatedPrinter(e) {
        e.preventDefault();
        console.log(e.target.name.value);
        console.log(e.target.status.value);

        const url = settings.API_URL + "api/printers/update";

        const newPrinter =
        {
            id: selectedPrinter._id,
            name: e.target.name.value,
            status: e.target.status.value
        };

        console.log(selectedPrinter);

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newPrinter),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Printer updated!");
            getAllPrinters();
            closeEdit();
        });
    }

    return (
        <div className="App pastel-orange-bg">
            <Header></Header>
            <div className='app-container pastel-orange-bg'>
            <h1>Printers</h1>
            <Fab className="add-fab" onClick={add}>
                <Icon>add</Icon>
            </Fab>

            <div>
                <table className="data-table">
                    <thead>
                        <tr>
                            
                            <th className="not-for-mobile">No.</th>
                            <th className="not-for-mobile">Name</th>
                            <th className="for-mobile">Printer</th>
                            <th>Status</th>
                            <th className="not-for-mobile">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allPrinters.map((item, key) =>
                            <tr key={key}>
                                <td className="not-for-mobile">PRT-{item.serial}</td>
                                <td className="not-for-mobile">{item.name}</td>
                                <td className="for-mobile">
                                    <p className='sub-text'>PRT-{item.serial}</p>
                                    {item.name}
                                    <p className='action-line'><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></p>
                                </td>
                                <td>{item.status}</td>
                                <td className='action-cell not-for-mobile'><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <SwipeableDrawer anchor='bottom' open={showAddPrinter} onClose={closeAdd} onOpen={openAdd} >
                <div className="drawer-content">
                    <h3>Add Printer</h3>
                    <form id="addPrinterForm" className='add-form' onSubmit={submitNewPrinter} >
                        <div className='add-form-container'>
                            <div className='add-form-section'>
                              <FormControl fullWidth>
                                    <InputLabel id="status-select-label">Status</InputLabel>
                                        <Select id="staus-select" label="Status" name='status' defaultValue={'New'}>
                                            <MenuItem value={'New'}>New</MenuItem>
                                            <MenuItem value={'Working'}>Working</MenuItem>
                                            <MenuItem value={'Service'}>Service</MenuItem>
                                        </Select>
                                </FormControl>  

                                <div className='drawer-input'><TextField fullWidth label='Name' name="name" variant='outlined' required></TextField></div>
                                {/* <div className='drawer-input'><TextField name="status" fullWidth multiline={true} rows={4} label='Status' variant='outlined' required></TextField></div> */}
                        
                            </div>
                            <div className='add-form-section add-image-container'>
                                <div className='image-box'>
                                    <Icon className='add-photo-icon'>add_a_photo</Icon>
                                </div>
                            </div>
                            <div className='add-form-section save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Save</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeAdd}>Cancel</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SwipeableDrawer>
            <SwipeableDrawer anchor='bottom' open={showEditPrinter} onClose={closeEdit} onOpen={openEdit} className="bottom-drawer">
                <div className="drawer-content">
                    <h3>Edit Printer</h3>
                    <form id="editProductForm" className='add-form' onSubmit={submitUpdatedPrinter} >
                        <div className='add-form-container'>
                            <div>
                               <FormControl fullWidth>
                                    <InputLabel id="status-select-label">Status</InputLabel>
                                        <Select id="staus-select" label="Status" name='status' defaultValue={selectedPrinter.status}>
                                            <MenuItem value={'New'}>New</MenuItem>
                                            <MenuItem value={'Working'}>Working</MenuItem>
                                            <MenuItem value={'Service'}>Service</MenuItem>
                                        </Select>
                                </FormControl>  

                                <div className='drawer-input'><TextField fullWidth label='Name' name="name" variant='outlined' defaultValue={selectedPrinter.name} required></TextField></div>
                                {/* <div className='drawer-input'><TextField name="status" fullWidth multiline={true} rows={4} label='Status' variant='outlined' defaultValue={selectedPrinter.status} required></TextField></div> */}
                            
                            
                            </div>
                            <div className='add-image-container'>
                                <div className='image-box'>
                                    <Icon className='add-photo-icon'>add_a_photo</Icon>
                                </div>
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Update</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeEdit}>Cancel</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SwipeableDrawer>
            <Modal
                open={confirmDeleteOpen}
                onClose={closeConfirmDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-box'>
                    <p>Are you sure you want to permanately delete <b>{selectedPrinter.name}</b>?</p>

                    <div className='center'>
                        <Button className='margin' variant="outlined" color="error" onClick={deteteItem}>Yes</Button>

                        <Button className='margin' variant="outlined" onClick={closeConfirmDelete}>No</Button>
                    </div>
                </Box>
            </Modal>
            <Snackbar open={openNotification}
                onClose={closeNotification}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={5000}>
                <Alert onClose={closeNotification} severity="success">
                    {notificationMsg}
                </Alert>
            </Snackbar>
            <Navigation></Navigation>
            </div>
        </div>
    );
}
import React from 'react';
import './Login.css';
import { Fab, Button, Icon, TextField, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import settings from '../../settings.json';

export default function Login(props) {
    let navigate = useNavigate();

    const [loginCred, setLoginCred] = React.useState({ email: '', password: '' });
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loginSucess, setLoginSucess] = React.useState(false);

    function handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        setLoginCred({ ...loginCred, [name]: value });
    }

    async function loginClick(e) {
        e.preventDefault();

        const url = await settings.API_URL + "api/users/login";

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(loginCred),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            res.json().then(res => {
                //console.log(res);
                if (res["_id"]) {
                    //console.log(res);
                    localStorage.setItem('MyPrintFarm_LoggedInUser', JSON.stringify({ id: res['_id'], email: res['email'], token: res['token'] }));
                    navigate('/');
                }
                else {
                    setOpenSnackbar(true);
                    setErrorMsg("Invalid email and/or password.");
                };
            });
        });
    }

    function handleCloseSnackbar() {
        setOpenSnackbar(false);
    }

    return (
        <div className="mainBody login-card">

            <form id="loginForm" onSubmit={loginClick}>
                <h1>Enter App</h1>
                <div className="form-group input-row" >
                    <TextField label="Email" variant="outlined" type="email" className="form-control" id="emailTxt" name="email" value={loginCred.email} onChange={handleInputChange} fullWidth required />
                </div>
                <div className="form-group input-row" >
                    <TextField label="Password" variant="outlined" type="password" className="form-control" id="passwordTxt" name="password" value={loginCred.password} onChange={handleInputChange} fullWidth required></TextField>
                </div>
                <div className="form-group input-row">
                    <Button type="submit" size="large" form="loginForm" variant="outlined" fullWidth>Login</Button>
                </div>
                {errorMsg !== "" ? <div className="error-container">{errorMsg}</div> : <div></div>}
            </form>

            <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} >
                <Alert severity="error" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
        </div>


    )
}
import React from 'react';
import './ProductList.css';
import Header from '../Common/Header';

import Navigation from '../Common/Navigation';
import { Fab, Icon, Button, SwipeableDrawer, TextField, FormGroup,Checkbox, FormControlLabel, Snackbar, Alert, Modal, Box, Radio, RadioGroup } from '@mui/material';
import settings from '../../settings.json';

export default function ProductList() {

    const [showAddProduct, setShowAddProduct] = React.useState(false);
    const [showEditProduct, setShowEditProduct] = React.useState(false);
    const [showAddInventory, setShowAddInventory] = React.useState(false);
    const [showRemoveInventory, setShowRomoveInventory] = React.useState(false);
    const [selectedProduct, setSelectedProduct] = React.useState(false);
    const [openNotification, setOpenNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState("");
    const [allProducts, setAllProducts] = React.useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [removeInventoryList, setRemoveInvetoryList] = React.useState([]);
    const [loggedInUser, setLoggedInUser] = React.useState({});

    React.useEffect(() => {
        getAllProducts();
        let curUser =  JSON.parse(localStorage.getItem("MyPrintFarm_LoggedInUser"));
        setLoggedInUser(curUser);
    }, []);

    function getAllProducts() {
        fetch(settings.API_URL + 'api/products/getall')
            .then(data => data.json())
            .then(data => {
                setAllProducts(data);
            });
    }

    function add() {
        setShowAddProduct(true);

    }





    function edit(item) {
        setShowEditProduct(true);
        setSelectedProduct(item);
    }


    function confirmDelete(item) {
        setConfirmDeleteOpen(true);
        setSelectedProduct(item);
    }

    function closeConfirmDelete() {
        setConfirmDeleteOpen(false);
    }

    function deteteItem() {
        setConfirmDeleteOpen(false);

        const url = settings.API_URL + "api/products/delete";

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(selectedProduct),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Product removed!");
            getAllProducts();
        });
    }

    function closeNotification() {
        setOpenNotification(false);
    }

    function closeAdd() {
        setShowAddProduct(false);
    }
    function closeEdit() {
        setShowEditProduct(false);
    }

    function openAdd() { }
    function openEdit() { }

    function submitNewProduct(e) {
        e.preventDefault();
        console.log(e.target.name.value);
        console.log(e.target.description.value);

        const url = settings.API_URL + "api/products/add";

        const newProduct =
        {
            name: e.target.name.value,
            description: e.target.description.value
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newProduct),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("save done");
            setOpenNotification(true);
            setNotificationMsg("New product added!");
            getAllProducts();
            closeAdd();
        });
    }

    function submitUpdatedProduct(e) {
        e.preventDefault();
        console.log(e.target.name.value);
        console.log(e.target.description.value);

        const url = settings.API_URL + "api/products/update";

        const newProduct =
        {
            id: selectedProduct._id,
            name: e.target.name.value,
            description: e.target.description.value,
            updatedBy: loggedInUser.id
        };

        console.log(selectedProduct);

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newProduct),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Product updated!");
            getAllProducts();
            closeEdit();
        });
    }

    function addInventory(item) {
        setSelectedProduct(item);
        setShowAddInventory(true);

    }

    function removeInventory(item) {
        setSelectedProduct(item);
        setShowRomoveInventory(true);


    }

    function submitRemoveInventory(e) {
        e.preventDefault();
        console.log(removeInventoryList);
       

        const url = settings.API_URL + "api/products/inventory/remove";

        const inventoryToRemove =
        {
            removeList: removeInventoryList,
            productId: selectedProduct._id,
            updatedBy: loggedInUser.id
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(inventoryToRemove),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Inventory removed!");
            getAllProducts();
            closeRemoveInventory();
        });
    
    }


    function submitNewInventory(e) {
        e.preventDefault();

        const url = settings.API_URL + "api/products/inventory/add";
     
        const newInvenory =
        {
            value: e.target.value.value,
            description: e.target.description.value,
            productId: selectedProduct._id,
            createdBy: loggedInUser.id
        };

        console.log("newInventory",newInvenory );

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newInvenory),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("save done");
            setOpenNotification(true);
            setNotificationMsg("New inventory added!");
            getAllProducts();
            closeAddInventory();
        });

    }

    function closeAddInventory() {
        setShowAddInventory(false);
    }

    function closeRemoveInventory() {
        setShowRomoveInventory(false);
    }

    function inventoryCheckboxChange(e){
        console.log(e.target.value);
        if(e.target.checked){
            if(!removeInventoryList.includes(e.target.value))
            {   
                let tmpList =  removeInventoryList;
                tmpList.push(e.target.value);
                setRemoveInvetoryList(tmpList);
            }
            console.log(removeInventoryList);
       }
       else if(!e.target.checked)
       {
         let tmpList  = removeInventoryList.filter(item=> item !== e.target.value);
         setRemoveInvetoryList(tmpList);
       }

    }

    return (
        <div className="App pastel-blue-bg">
            <Header></Header>
            <div className='app-container pastel-blue-bg'>
                <h1>Products</h1>
                <Fab className="add-fab" onClick={add}>
                    <Icon>add</Icon>
                </Fab>

                <div>
                    <table className="data-table">
                        <thead>
                            <tr>
                                <th className='not-for-mobile'>Product</th>
                                <th className='not-for-mobile'>Description</th>
                                <th className='for-mobile'>Product</th>
                                <th>Inventory</th>
                                <th className='not-for-mobile'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allProducts.map((item, key) =>
                                <tr key={key}>
                                    <td className='not-for-mobile'>{item.name}</td>
                                    <td className='not-for-mobile'>{item.description}</td>
                                    <td className='for-mobile'>
                                        {item.name}
                                        <p className='sub-text'>{item.description}</p>
                                        <p className='action-line'><Icon>list_alt</Icon><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></p>
                                    </td>
                                    <td>
                                        <div className='inventory-section'>
                                            <div >
                                                <Icon className='inventory-icon' onClick={() => removeInventory(item)}>remove_circle</Icon>
                                            </div>
                                            <div>
                                                <span className='inventory-count'>{item.inventory ? item.inventory.length : 0} </span>
                                            </div>
                                            <div>
                                                <Icon className='inventory-icon' onClick={() => addInventory(item)}>add_circle</Icon>
                                            </div>
                                        </div>
                                    </td>

                                    <td className='action-cell not-for-mobile' ><Icon>list_alt</Icon><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <SwipeableDrawer anchor='bottom' open={showAddProduct} onClose={closeAdd} onOpen={openAdd} >
                    <div className="drawer-content">
                        <h3>Add Product</h3>
                        <form id="addProductForm" className='add-form' onSubmit={submitNewProduct} >
                            <div className='add-form-container'>
                                <div>
                                    <div className='drawer-input'><TextField fullWidth label='Name' name="name" variant='outlined' required></TextField></div>
                                    <div className='drawer-input'><TextField name="description" fullWidth multiline={true} rows={4} label='Description' variant='outlined' required></TextField></div>
                                </div>
                                <div className='add-image-container'>
                                    <div className='image-box'>
                                        <Icon className='add-photo-icon'>add_a_photo</Icon>
                                    </div>
                                </div>
                                <div className='save-button-container'>
                                    <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Save</Button>
                                    <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeAdd}>Cancel</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </SwipeableDrawer>
                <SwipeableDrawer anchor='bottom' open={showEditProduct} onClose={closeEdit} onOpen={openEdit} className="bottom-drawer">
                    <div className="drawer-content">
                        <h3>Edit Product</h3>
                        <form id="editProductForm" className='add-product-form' onSubmit={submitUpdatedProduct} >
                            <div className='add-product-form-container'>
                                <div>
                                    <div className='drawer-input'><TextField fullWidth label='Name' name="name" variant='outlined' defaultValue={selectedProduct.name} required></TextField></div>
                                    <div className='drawer-input'><TextField name="description" fullWidth multiline={true} rows={4} label='Description' variant='outlined' defaultValue={selectedProduct.description} required></TextField></div>
                                </div>
                                <div className='add-image-container'>
                                    <div className='image-box'>
                                        <Icon className='add-photo-icon'>add_a_photo</Icon>
                                    </div>
                                </div>
                                <div className='save-button-container'>
                                    <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Update</Button>
                                    <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeEdit}>Cancel</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </SwipeableDrawer>
                <Modal
                    open={confirmDeleteOpen}
                    onClose={closeConfirmDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modal-box'>
                        <p>Are you sure you want to permanately delete <b>{selectedProduct.name}</b>?</p>

                        <div className='center'>
                            <Button className='margin' variant="outlined" color="error" onClick={deteteItem}>Yes</Button>

                            <Button className='margin' variant="outlined" onClick={closeConfirmDelete}>No</Button>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={showAddInventory}
                    onClose={closeAddInventory}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modal-box'>
                        <h3>Add Inventory</h3>
                        <form id="addInventoryForm" className='add-product-form' onSubmit={submitNewInventory} >

                            <div>
                                <div className='drawer-input'><TextField fullWidth label='Value' name="value" variant='outlined' type="number" required></TextField></div>
                                <div className='drawer-input'><TextField name="description" fullWidth multiline={true} rows={4} label='Description' variant='outlined' required></TextField></div>
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Add</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeAddInventory}>Cancel</Button>
                            </div>

                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={showRemoveInventory}
                    onClose={closeRemoveInventory}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modal-box'>
                        <h3>Remove Inventory</h3>
                        <form id="removeInventoryForm" className='add-product-form' onSubmit={submitRemoveInventory} >
                            <div className='remove-inventory-list'>
                                <FormGroup>
                                {selectedProduct?.inventory?.map((item, key) =>
                                     <FormControlLabel key={item.id} value={item.id}
                                     control={
                                       <Checkbox value={item.id} onChange={inventoryCheckboxChange}/>
                                     }
                                     label={item.description}
                                   />
                                )}
                                </FormGroup>
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Remove</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeRemoveInventory}>Cancel</Button>
                            </div>

                        </form>
                    </Box>
                </Modal>
                <Snackbar open={openNotification}
                    onClose={closeNotification}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    autoHideDuration={5000}>
                    <Alert onClose={closeNotification} severity="success">
                        {notificationMsg}
                    </Alert>
                </Snackbar>
                <Navigation></Navigation>
            </div>
        </div>
    );
}
import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Link } from 'react-router-dom';
import Header from './components/Common/Header';
import settings from './settings.json';

function App() {

  const [productStats, setProductStats] = React.useState([]);
  const [materialStats, setMaterialStats] = React.useState([]);
  const [printerStats, setPrinterStats] = React.useState([]);

  React.useEffect(() => {
    getStats();
  }, []);

  function getStats() {
    fetch(settings.API_URL + 'api/products/stats')
      .then(data => data.json())
      .then(data => {
        console.log("product stats", data);
        setProductStats(data);
      });

    fetch(settings.API_URL + 'api/materials/stats')
      .then(data => data.json())
      .then(data => {
        console.log("material stats", data);
        setMaterialStats(data);
      });

    fetch(settings.API_URL + 'api/printers/stats')
      .then(data => data.json())
      .then(data => {
        console.log("printer stats", data);
        setPrinterStats(data);
      });
  }


  return (
    <div className="App">
      <Header></Header>
      <div className="main-container">

        <div className="left-container">
        <div className='stats-box'>{productStats[0]?.TotalCount}</div>
          <Link to="/products"><div className="box-header">Products</div></Link>
          <div className='stats-box'>${productStats[0]?.TotalValue}</div>
        </div>

        <div className="right-container">
          <div className="right-top-container">
          <div className='stats-box'>{printerStats[0]?.TotalCount}</div>
            <Link to="/printers"><div className="box-header">Printers</div></Link>
          </div>
          <div className="right-bottom-container">
          <div className='stats-box'>{materialStats[0]?.TotalCount}</div>
            <Link to="/materials"><div className="box-header">Materials</div></Link>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

import React from 'react';
import './ManageTickets.css';
import Header from '../Common/Header';
import Navigation from '../Common/Navigation';
import { Fab, Icon, Button, SwipeableDrawer, TextField, FormControl, Snackbar, Alert, Modal, Box, InputLabel, Select, MenuItem } from '@mui/material';
import settings from '../../settings.json';

export default function ManageTickets() {

    const [openNotification, setOpenNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState("");
    const [allTickets, setAllTickets] = React.useState([]);
    const [showAddTicket, setShowAddTicket] = React.useState(false);
    const [showEditTicket, setShowEditTicket] = React.useState(false);
    const [selectedTicket, setSelectedTicket] = React.useState({});
    const [confirmDeleteOpen, setConfirmDeleteOpen] =  React.useState(false);


    React.useEffect(() => {
        getAllTickets();
    }, []);

    function getAllTickets() {
        fetch(settings.API_URL + 'api/tickets/getAll')
            .then(data => data.json())
            .then(data => {
                setAllTickets(data);
            });
    }

    function add() {
        setShowAddTicket(true);

    }


    function edit(item) {
        setShowEditTicket(true);
        setSelectedTicket(item);
    }


    function confirmDelete(item) {
        setConfirmDeleteOpen(true);
        setSelectedTicket(item);
    }

    function closeConfirmDelete() {
        setConfirmDeleteOpen(false);
    }

    function deteteItem() {
        setConfirmDeleteOpen(false);

        const url = settings.API_URL + "api/tickets/delete";

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(selectedTicket),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Ticket removed!");
            getAllTickets();
        });
    }

    function closeNotification() {
        setOpenNotification(false);
    }

    function closeAdd() {
        setShowAddTicket(false);
    }
    function closeEdit() {
        setShowEditTicket(false);
    }

    function openAdd() { }
    function openEdit() { }

    function submitNewTicket(e) {
        e.preventDefault();

        const url = settings.API_URL + "api/tickets/add";

        const newTicket =
        {
            status: e.target.status.value,
            description: e.target.description.value
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newTicket),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("save done");
            setOpenNotification(true);
            setNotificationMsg("New ticket added!");
            getAllTickets();
            closeAdd();
        });
    }

    function submitUpdatedTicket(e) {
        e.preventDefault();
        console.log(e.target.description.value);
        console.log(e.target.status.value);

        const url = settings.API_URL + "api/tickets/update";

        const newTicket =
        {
            id: selectedTicket._id,
            description: e.target.description.value,
            status: e.target.status.value
        };

        //console.log(selectedPrinter);

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newTicket),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Ticket updated!");
            getAllTickets();
            closeEdit();
        });
    }

    return (
        <div className="App gray-bg">
            <Header></Header>
            <div className='app-container'>
                <h1>Manage Tickets</h1>
                <Fab className="add-fab" onClick={add}>
                    <Icon>add</Icon>
                </Fab>

                <div>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th className="not-for-mobile">No.</th>
                            <th >Description</th>
                            <th >Status</th>
                            <th  className="not-for-mobile">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTickets.map((item, key) =>
                            <tr key={key}>
                               <td className="not-for-mobile">TK-{item.serial}</td>
                                <td className="not-for-mobile">{item.description}</td>
                                <td className="for-mobile">
                                <p className='sub-text'>TK-{item.serial}</p>
                                {item.description}
                                <p className='action-line'><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></p>
                                </td>
                                <td>{item.status}</td>
                                <td className='action-cell not-for-mobile'><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
                <SwipeableDrawer anchor='bottom' open={showAddTicket} onClose={closeAdd} onOpen={openAdd} >
                    <div className="drawer-content">
                        <h3>Add Ticket</h3>
                        <form id="addTicketForm" className='add-form' onSubmit={submitNewTicket} >
                            <div className='add-form-container'>
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-select-label">Status</InputLabel>
                                        <Select id="staus-select" label="Status" name='status' defaultValue={'New'}>
                                            <MenuItem value={'New'}>New</MenuItem>
                                            <MenuItem value={'In Progress'}>In Progress</MenuItem>
                                            <MenuItem value={'Completed'}>Completed</MenuItem>
                                        </Select>
                                    </FormControl>             
                                    <div className='drawer-input'><TextField fullWidth label='Description' name="description" variant='outlined' multiline={true} rows={4} required></TextField></div>
                                    {/* <div className='drawer-input'><TextField name="status" fullWidth multiline={true} rows={4} label='Status' variant='outlined' required></TextField></div> */}
                                </div>
                                <div className='add-image-container'>
                                    <div className='image-box'>
                                        <Icon className='add-photo-icon'>add_a_photo</Icon>
                                    </div>
                                </div>
                                <div className='save-button-container'>
                                    <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Save</Button>
                                    <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeAdd}>Cancel</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </SwipeableDrawer>
                <SwipeableDrawer anchor='bottom' open={showEditTicket} onClose={closeEdit} onOpen={openEdit} className="bottom-drawer">
                <div className="drawer-content">
                    <h3>Edit Ticket</h3>
                    <form id="editTicketForm" className='add-form' onSubmit={submitUpdatedTicket} >
                        <div className='add-form-container'>
                            <div>
                                <FormControl fullWidth>
                                    <InputLabel id="status-select-label">Status</InputLabel>
                                        <Select id="staus-select" label="Status" name='status' defaultValue={selectedTicket.status}>
                                            <MenuItem value={'New'}>New</MenuItem>
                                            <MenuItem value={'In Progress'}>In Progress</MenuItem>
                                            <MenuItem value={'Completed'}>Completed</MenuItem>
                                        </Select>
                                </FormControl>             
                                <div className='drawer-input'><TextField fullWidth label='Description' name="description" variant='outlined' multiline={true} rows={4} defaultValue={selectedTicket.description} required></TextField></div>
                            
                               
                            </div>
                            <div className='add-image-container'>
                                <div className='image-box'>
                                    <Icon className='add-photo-icon'>add_a_photo</Icon>
                                </div>
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Update</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeEdit}>Cancel</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SwipeableDrawer>
            <Modal
                open={confirmDeleteOpen}
                onClose={closeConfirmDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-box'>
                    <p>Are you sure you want to permanately delete <b>{setSelectedTicket._id}</b>?</p>

                    <div className='center'>
                        <Button className='margin' variant="outlined" color="error" onClick={deteteItem}>Yes</Button>

                        <Button className='margin' variant="outlined" onClick={closeConfirmDelete}>No</Button>
                    </div>
                </Box>
            </Modal> 
            <Snackbar open={openNotification}
                onClose={closeNotification}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={5000}>
                <Alert onClose={closeNotification} severity="success">
                    {notificationMsg}
                </Alert>
            </Snackbar> 
                <Navigation></Navigation>
            </div>
        </div>
    );
}
import React from 'react';
import './Navigation.css';
import {  Fab, Button, Icon, TextField } from '@mui/material';
import { useNavigate,Link, Redirect } from 'react-router-dom';
import settings from '../../settings.json';

export default function Navigation(props) {
    let navigate = useNavigate();

    React.useEffect(() => {

        checkUser();
    })

function checkUser(){

        let curUser =  localStorage.getItem("MyPrintFarm_LoggedInUser");
        let token = JSON.parse(curUser).token;
        console.log("token",token);

        let header = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json' 
                    };

        fetch(settings.API_URL + 'api/users/authenticate',{
        method: 'POST',
        headers: header})
        .then(response => {

            if(response["status"] == "403")
                navigate("/login");
        })
        .catch( (error) =>{
            console.log(error);
            navigate("/login");
        });

        if(!curUser){
           navigate("/login");
        }
    }


    return (
        <div className="navigation-section">
           <div className='navigation-item'><Link to="/"><div className='navigation-div'><Icon className='nav-icon'>dashboard</Icon> Dashboard</div></Link></div>
           <div className='navigation-item pastel-blue-bg'><Link to="/products"><div className='navigation-div'><Icon className='nav-icon'>inventory_2</Icon> Products</div></Link></div>
           <div className='navigation-item pastel-orange-bg'><Link to="/printers"><div className='navigation-div'><Icon className='nav-icon'>printer</Icon> Printers</div></Link></div>
           <div className='navigation-item pastel-red-bg'><Link to="/materials"><div className='navigation-div'><Icon className='nav-icon'>trip_origin</Icon> Materials</div></Link></div>
        </div>
    )
}
import React from 'react';
import './Header.css';
import {  Fab, Button, Icon, TextField } from '@mui/material';
import { useNavigate,Link, Redirect } from 'react-router-dom';
import settings from '../../settings.json';

export default function Header(props) {

    const [loggedInUser, setLoggedInUser] = React.useState({});

    let navigate = useNavigate();

    React.useEffect(() => {

        checkUser();
    })

function checkUser(){

        let curUser =  localStorage.getItem("MyPrintFarm_LoggedInUser");
        setLoggedInUser(curUser);
        console.log("loggedInUser", loggedInUser);
       
        

        let token;

        if(!curUser){
            navigate("/login");
            token = "";
        }
        else{
            
            token = JSON.parse(curUser).token;
        }
        
        let header = {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json' 
                    };

        fetch(settings.API_URL + 'api/users/authenticate',{
        method: 'POST',
        headers: header})
        .then(response => {

            if(response["status"] == "403")
                navigate("/login");
        })
        .catch( (error) =>{
            console.log(error);
            navigate("/login");
        });

        if(!curUser){
           navigate("/login");
        }
    }

    return (
        <div className='header-section'>
             <div className='header-item'><Link to="/tickets"><div className='header-div'><Icon className='header-icon'>bug_report</Icon>Tickets</div></Link></div>
             <div className='header-item'><Link to="/todos"><div className='header-div'><Icon className='header-icon'>task</Icon>To Do list</div></Link></div>
        </div>
    )
}
import React from 'react';
import './MaterialList.css';
import Navigation from '../Common/Navigation';
import { Fab, Icon, Button, SwipeableDrawer, TextField, FormControl, Snackbar, Alert, Modal, Box, FormGroup, Checkbox, FormControlLabel } from '@mui/material';
import settings from '../../settings.json';
import Header from '../Common/Header';
export default function MaterialList() {

    const [showAddMaterial, setShowAddMaterial] = React.useState(false);
    const [showEditMaterial, setShowEditMaterial] = React.useState(false);
    const [selectedMaterial, setSelectedMaterial] = React.useState(false);
    const [openNotification, setOpenNotification] = React.useState(false);
    const [notificationMsg, setNotificationMsg] = React.useState("");
    const [allMaterials, setAllMaterials] = React.useState([]);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
    const [showAddInventory, setShowAddInventory] = React.useState(false);
    const [showRemoveInventory, setShowRemoveInventory] = React.useState(false);
    const [removeInventoryList, setRemoveInventoryList] = React.useState([]);

    React.useEffect(() => {
        getAllMaterials();
    }, []);

    function getAllMaterials() {
        fetch(settings.API_URL + 'api/materials/getall')
            .then(data => data.json())
            .then(data => {
                setAllMaterials(data);
            });
    }

    function add() {
        setShowAddMaterial(true);
    }


    function edit(item) {
        setShowEditMaterial(true);
        setSelectedMaterial(item);
    }


    function confirmDelete(item) {
        setConfirmDeleteOpen(true);
        setSelectedMaterial(item);
    }

    function closeConfirmDelete() {
        setConfirmDeleteOpen(false);
    }

    function deteteItem() {
        setConfirmDeleteOpen(false);

        const url = settings.API_URL + "api/materials/delete";

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(selectedMaterial),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Material removed!");
            getAllMaterials();
        });
    }

    function closeNotification() {
        setOpenNotification(false);
    }

    function closeAdd() {
        setShowAddMaterial(false);
    }
    function closeEdit() {
        setShowEditMaterial(false);
    }

    function openAdd() { }
    function openEdit() { }

    function submitNewMaterial(e) {
        e.preventDefault();
        console.log(e.target.name.value);
        console.log(e.target.description.value);

        const url = settings.API_URL + "api/materials/add";
        const newMaterial =
        {
            name: e.target.name.value,
            description: e.target.description.value
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newMaterial),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("save done");
            setOpenNotification(true);
            setNotificationMsg("New printer added!");
            getAllMaterials();
            closeAdd();
        });
    }

    function submitUpdatedMaterial(e) {
        e.preventDefault();
        console.log(e.target.name.value);
        console.log(e.target.description.value);

        const url = settings.API_URL + "api/materials/update";

        const newMaterial =
        {
            id: selectedMaterial._id,
            name: e.target.name.value,
            description: e.target.description.value
        };

        console.log(selectedMaterial);

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newMaterial),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Material updated!");
            getAllMaterials();
            closeEdit();
        });
    }

    function submitRemoveInventory(e) {
        e.preventDefault();
        console.log(removeInventoryList);
       
        const url = settings.API_URL + "api/materials/inventory/remove";

        const inventoryToRemove =
        {
            materialId: selectedMaterial._id
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(inventoryToRemove),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            setOpenNotification(true);
            setNotificationMsg("Inventory removed!");
            getAllMaterials();
            closeRemoveInventory();
        });
    
    }

    const delay = async ( ms = 1) => new Promise( resolve => setTimeout(resolve, ms));

    async function submitNewInventory(e) {
        e.preventDefault();
        let quantity = e.target.quantity.value;

        for(let i= 0; i < quantity; i++)
        {
            addSingleInventory();
            await delay(1);
        }

        setOpenNotification(true);
        setNotificationMsg("New inventory added!");
        getAllMaterials();
        closeAddInventory();
    }

    function addSingleInventory(){
        const url = settings.API_URL + "api/materials/inventory/add";

        const newInvenory =
        {
            materialId: selectedMaterial._id
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(newInvenory),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => {
            console.log("save done");
            
        });
    }

    function closeAddInventory() {
        setShowAddInventory(false);
    }

    function closeRemoveInventory() {
        setShowRemoveInventory(false);
    }

    function inventoryCheckboxChange(e){
        console.log(e.target.value);
        if(e.target.checked){
            if(!removeInventoryList.includes(e.target.value))
            {   
                let tmpList =  removeInventoryList;
                tmpList.push(e.target.value);
                setRemoveInventoryList(tmpList);
            }
            console.log(removeInventoryList);
       }
       else if(!e.target.checked)
       {
         let tmpList  = removeInventoryList.filter(item=> item !== e.target.value);
         setRemoveInventoryList(tmpList);
       }
    }

    function addInventory(item){
        setShowAddInventory(true);
        setSelectedMaterial(item);
        
    }
    function  removeInventory(item){
        setShowRemoveInventory(true);
        setSelectedMaterial(item);
    }

    return (
        <div className="App pastel-red-bg">
            <Header></Header>
            <div className='app-container pastel-red-bg'>
            <h1>Materials</h1>
            <Fab className="add-fab" onClick={add}>
                <Icon>add</Icon>
            </Fab>

            <div>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th className='not-for-mobile'>Name</th>
                            <th className='not-for-mobile'>Description</th>
                            <th className='for-mobile'>Name</th>
                            <th>Inventory</th>
                            <th className='not-for-mobile'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allMaterials.map((item, key) =>
                            <tr key={key}>
                                <td className='not-for-mobile'>{item.name}</td>
                                <td className='not-for-mobile'>{item.description}</td>
                                <td className='for-mobile'>
                                    {item.name}
                                  <p className='sub-text'>{item.description}</p>
                                  <p className='action-line'><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></p>
                                </td>
                                <td>
                                <div className='inventory-section'>
                                            <div>
                                                <Icon className='inventory-icon' onClick={() => removeInventory(item)}>remove_circle</Icon>
                                            </div>
                                            <div>
                                                <span className='inventory-count'>{item.inventory?item.inventory.length:0}</span>
                                            </div>
                                            <div>
                                                <Icon className='inventory-icon' onClick={() => addInventory(item)}>add_circle</Icon>
                                            </div>
                                        </div>
                                        </td>
                                <td className='action-cell not-for-mobile'><Icon onClick={() => edit(item)}>edit</Icon><Icon onClick={() => confirmDelete(item)}>delete</Icon></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <SwipeableDrawer anchor='bottom' open={showAddMaterial} onClose={closeAdd} onOpen={openAdd} >
                <div className="drawer-content">
                    <h3>Add Material</h3>
                    <form id="addMaterialForm" className='add-form' onSubmit={submitNewMaterial} >
                        <div className='add-form-container'>
                            <div>
                                <div className='drawer-input'><TextField fullWidth label='Name' name="name" variant='outlined' required></TextField></div>
                                <div className='drawer-input'><TextField name="description" fullWidth multiline={true} rows={4} label='Description' variant='outlined' required></TextField></div>
                            </div>
                            <div className='add-image-container'>
                                <div className='image-box'>
                                    <Icon className='add-photo-icon'>add_a_photo</Icon>
                                </div>
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Save</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeAdd}>Cancel</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SwipeableDrawer>
            <SwipeableDrawer anchor='bottom' open={showEditMaterial} onClose={closeEdit} onOpen={openEdit} className="bottom-drawer">
                <div className="drawer-content">
                    <h3>Edit Material</h3>
                    <form id="editProductForm" className='add-form' onSubmit={submitUpdatedMaterial} >
                        <div className='add-form-container'>
                            <div>
                                <div className='drawer-input'><TextField fullWidth label='Name' name="name" variant='outlined' defaultValue={selectedMaterial.name} required></TextField></div>
                                <div className='drawer-input'><TextField name="description" fullWidth multiline={true} rows={4} label='Description' variant='outlined' defaultValue={selectedMaterial.description} required></TextField></div>
                            </div>
                            <div className='add-image-container'>
                                <div className='image-box'>
                                    <Icon className='add-photo-icon'>add_a_photo</Icon>
                                </div>
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Update</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeEdit}>Cancel</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SwipeableDrawer>
            <Modal
                open={confirmDeleteOpen}
                onClose={closeConfirmDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='modal-box'>
                    <p>Are you sure you want to permanately delete <b>{selectedMaterial.name}</b>?</p>

                    <div className='center'>
                        <Button className='margin' variant="outlined" color="error" onClick={deteteItem}>Yes</Button>
                        <Button className='margin' variant="outlined" onClick={closeConfirmDelete}>No</Button>
                    </div>
                </Box>
            </Modal>
            <Modal
                    open={showAddInventory}
                    onClose={closeAddInventory}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modal-box'>
                        <h3>Add Inventory</h3>
                        <form id="addInventoryForm" className='add-product-form' onSubmit={submitNewInventory} >
                            <div>
                               Add <input type="number" defaultValue="1" min="1" max="10" name="quantity" className='quantity-input'></input> {selectedMaterial.name} to inventory?
                            </div>
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Add</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeAddInventory}>Cancel</Button>
                            </div>
                        </form>
                    </Box>
                </Modal>
                <Modal
                    open={showRemoveInventory}
                    onClose={closeRemoveInventory}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='modal-box'>
                        <h3>Remove Inventory</h3>
                        <form id="removeInventoryForm" className='add-product-form' onSubmit={submitRemoveInventory} >
                            Remove <b>1</b> {selectedMaterial.name} from inventory?
                            <div className='save-button-container'>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="contained" type="submit">Remove</Button>
                                <Button fullWidth style={{ height: "45%", margin: "0 0 5% 0" }} variant="outlined" onClick={closeRemoveInventory}>Cancel</Button>
                            </div>

                        </form>
                    </Box>
                </Modal>
            <Snackbar open={openNotification}
                onClose={closeNotification}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                autoHideDuration={5000}>
                <Alert onClose={closeNotification} severity="success">
                    {notificationMsg}
                </Alert>
            </Snackbar>
            <Navigation></Navigation>
            </div>
        </div>
    );
}